import { Create, SimpleForm, TextInput, NumberInput, BooleanInput, CheckboxGroupInput } from 'react-admin';

const jours = [
    { id: 1, name: 'Lundi' },
    { id: 2, name: 'Mardi' },
    { id: 3, name: 'Mercredi' },
    { id: 4, name: 'Jeudi' },
    { id: 5, name: 'Vendredi' },
    { id: 6, name: 'Samedi' },
    { id: 7, name: 'Dimanche' },
];

export const UserCreate = (props) => (
    <Create {...props} >
        <SimpleForm redirect="list">
            <BooleanInput source="active" />
            <TextInput source="nom" />
            <TextInput source="prenom" />
            <TextInput source="email" />
            <CheckboxGroupInput source="work_days.days" label="Jours travaillés" row choices={jours} />
            <CheckboxGroupInput source="days_off.days" label="Jours off" row choices={jours} />
            <NumberInput source="work_quota" label="Quotité temps" min={0} max={1} step={0.1}/>
            <TextInput source="comment" multiline/>
        </SimpleForm>
    </Create>
);
