import { Create, SimpleForm, BooleanInput, TextInput } from 'react-admin';

export const ProjectAxisCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <BooleanInput source="active" label="Actif" defaultValue={true}/>
            <TextInput source="code" />
            <TextInput source="name" label="Nom"/>
        </SimpleForm>
    </Create>
);
