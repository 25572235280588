import { Create, SimpleForm, BooleanInput, TextInput, ReferenceInput, RadioButtonGroupInput, SelectInput } from 'react-admin';

const type = [
    { id: 'P', name: 'Code Action' },
    { id: 'S', name: 'Code Gestion' },
];

export const TagCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <BooleanInput source="active" defaultValue={true} />
            <RadioButtonGroupInput source="type" row choices={type} defaultValue={'P'} />
            <TextInput source="code" />
            <TextInput source="name" label="Nom" />
            <TextInput source="operation" label="Groupe d’actions" />
            <ReferenceInput source="program_id" reference="programs" label="Programme" link={false}>
                <SelectInput optionText={record => `${record.name}`} />
            </ReferenceInput>
            <ReferenceInput source="manager" reference="users" label="Gestionnaire groupe d'actions" link={false}>
                <SelectInput optionText={record => `${record.prenom} ${record.nom}`} />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
