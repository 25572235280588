import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { styled } from '@material-ui/core/styles';
import { Box, makeStyles } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactToPrint from "react-to-print";
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableRowColumn from '@material-ui/core/TableCell';

const useStyles = makeStyles({
    header: { '@media print' : { pageBreakAfter:'always' }},
    tableContainer: { '@media print' : { pageBreakInside:'auto' }},
    table: { '@media print' : { pageBreakInside:'auto' }},
    trh: { height: 50, '@media print' : { pageBreakInside:'avoid', pageBreakAfter:'auto' }},
    tr: { height: 30, '@media print' : { pageBreakInside:'avoid', pageBreakAfter:'auto' }},
    td: { padding: "0px 16px", height: 'auto', '@media print' : { pageBreakInside:'avoid', pageBreakAfter:'auto' }}
});

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    backgroundColor: theme.palette.primary,
    height: 10,
    textAlign: "center",
    margin: 'auto'
  }));


function roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}

export default () => {
    const [state, setState] = useState({
        userList: {},
        eventList: {},
        totalHours: 0,
        absHours: 0,
        feriHours: 0,
        congHours: 0,
        hoursByProjects: {},
        actualUser: 0,
        actualUserName: "",
        actualFromDate: "2021-01-01",
        actualToDate: "2021-12-31"
    });
    const classes = useStyles();
    const version = useVersion();
    const dataProvider = useDataProvider();

    const fetchUserList = useCallback(async () => {
        const { data, total } = await dataProvider.getList('users', {
            filter: {
                active: 1
            },
            sort: {
                field: 'nom',
                order: 'ASC'
            },
            pagination: {
                page: 1,
                perPage: 1000
            }
        });
        setState((state) => ({
            ...state,
            userList: data,
            totalUserList: total
        }));
    }, [dataProvider]);
    const fetchProjects = useCallback(async (user = 0, from = "2021-01-01", to = "2021-12-31") => {
        const { data, total } = await dataProvider.getList('events', {
            filter: {
                user_id: user,
                from: from,
                to: to
            },
            sort: {
                field: ['project_code'],
                order: ['DESC']
            },
            pagination: {
                page: 1,
                perPage: 100
            },
            func: 'projects'
        });

        setState((state) => ({
            ...state,
            hoursByProjects: data
        }));
    }, [dataProvider]);
    const fetchEventList = useCallback(async (user = 0, from = "2021-01-01", to = "2021-12-31") => {
        const { data, total } = await dataProvider.getList('events', {
            filter: {
                user_id: user,
                from: from,
                to: to,
                projectsOnly: true
            },
            sort: {
                field: ['event_start_date'],
                order: ['ASC']
            },
            pagination: {
                page: 1,
                perPage: 10000
            }
        });


        const res = await eventByDay(data);
        
        const abs = ["ABS1", "ABS2"];
        const feri = ["FERI"];
        const cong = ["CONG","CONGDEMI","RECU"];
        const noproject = abs.concat(feri).concat(cong);

        var totalHours = 0;
        var absHours = 0;
        var feriHours = 0;
        var congHours = 0;

        data.map(line => totalHours += noproject.indexOf(line.project_code)<0 ? roundUp(line.hours.num, 2) : 0);
        data.map(line => absHours += abs.indexOf(line.project_code)>=0 ? roundUp(line.hours.num, 2) : 0);
        data.map(line => feriHours += feri.indexOf(line.project_code)>=0 ? roundUp(line.hours.num, 2) : 0);
        data.map(line => congHours += cong.indexOf(line.project_code)>=0 ? roundUp(line.hours.num, 2) : 0);

        setState((state) => ({
            ...state,
            eventList: res,
            totalHours,
            absHours,
            feriHours,
            congHours,
            totalEventList: total
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchUserList();
        fetchEventList();
        fetchProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [version]);

    const {
        userList,
        eventList,
        totalHours,
        absHours,
        feriHours,
        congHours,
        hoursByProjects,
        actualUser,
        actualUserName,
        actualFromDate,
        actualToDate
    } = state;

    const changeUser = (event, value) => {
        setState((state) => ({
            ...state,
            actualUser: event.target.value,
            actualUserName: value.props.children
        }));
    };

    const changeFromDate = (event) => {
        setState((state) => ({
            ...state,
            actualFromDate: event.target.value
        }));
    };

    const changeToDate = (event) => {
        setState((state) => ({
            ...state,
            actualToDate: event.target.value
        }));
    };

    const eventByDay = (data) => {
        var eventByDay = {}
        if(data.length > 0) {
            const weeks = [...new Set(data.map(line => line.week))];
            weeks.map(w => {
                const filteredWList = data.filter(line => line.week === w);
                const days = [...new Set(filteredWList.map(line => line.day))];
                eventByDay[w] = []
                days.map(d => {
                    const filteredDList = data.filter(line => line.day === d);
                    eventByDay[w][d] = filteredDList
                })
            })
        }
        return eventByDay
    };
        
    let componentRef = useRef();

    return (
        <FormControl>
            <Grid container spacing={2} rowSpacing={2} >
                <Grid item xs={12}>
                    <InputLabel shrink variant="standard" htmlFor="uncontrolled-native">Sélectionnez un salarié et une période</InputLabel>
                </Grid>
                <Grid item>
                    <Select
                        id="user"
                        displayEmpty
                        defaultValue=""
                        onChange = {changeUser} 
                    >
                        <MenuItem value="" disabled>
                            Salarié
                        </MenuItem>
                        {
                            userList.length > 0 && userList.map(u => (
                                <MenuItem value={u.id}>{[u.nom.toUpperCase(), ' ',u.prenom].join('')}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item>
                    <TextField
                        id="from-date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={actualFromDate}
                        onChange={changeFromDate}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="to-date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={actualToDate}
                        onChange={changeToDate}
                    />
                </Grid>
                <Grid item>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={() => {
                            fetchEventList(actualUser, actualFromDate, actualToDate)
                            fetchProjects(actualUser, actualFromDate, actualToDate)
                        }}
                    >AFFICHER</Button>
                </Grid>
                <Grid item>
                    <ReactToPrint
                        trigger={() => <Button variant="contained" color="primary">IMPRIMER</Button>}
                        content={() => componentRef}
                        documentTitle="TimeManager - Listing"
                        copyStyles = {true}
                        pageStyle = "@page { size: auto; margin: 10mm; } }"
                    />
                </Grid>
            </Grid>
            <div ref={(el) => (componentRef = el)}>
            <div className={classes.header}>
            {
                Object.keys(eventList).length > 0 &&
                <>
                <Box
                    height={75}
                    width={'100%'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#B3B0B0"
                    color="black"
                    fontSize={24}
                >
                    {actualUserName} du {actualFromDate} au {actualToDate}
                </Box>
                <Table options={{ padding: "dense", }} className={classes.table}>
                    <TableRow className={classes.trh}>
                        <TableRowColumn style={{backgroundColor:'#94BEE9', fontWeight:'bold'}} className={classes.td} colSpan={3}> 
                            <Typography variant="h6" component="h2">TOTAUX</Typography>
                        </TableRowColumn>
                    </TableRow>
                    <TableRow>
                        <TableRowColumn style={{width:'50%'}} className={classes.td}>
                            <Typography variant="body1">Travail effectif</Typography>
                        </TableRowColumn>
                        <TableRowColumn style={{width:'10%', textAlign:'right'}} className={classes.td}>
                            <Typography variant="body1">{roundUp(totalHours,2)}h</Typography>
                        </TableRowColumn>
                        <TableRowColumn style={{width:'40%'}} className={classes.td}></TableRowColumn>
                    </TableRow>
                    <TableRow>
                        <TableRowColumn style={{width:'50%'}} className={classes.td}>
                            <Typography variant="body1">Fériés</Typography>
                        </TableRowColumn>
                        <TableRowColumn style={{width:'10%', textAlign:'right'}} className={classes.td}>
                            <Typography variant="body1">{roundUp(feriHours,2)}h</Typography>
                        </TableRowColumn>
                        <TableRowColumn style={{width:'40%'}} className={classes.td}></TableRowColumn>
                    </TableRow>
                    <TableRow>
                        <TableRowColumn style={{width:'50%'}} className={classes.td}>
                            <Typography variant="body1">Absences</Typography>
                        </TableRowColumn>
                        <TableRowColumn style={{width:'10%', textAlign:'right'}} className={classes.td}>
                            <Typography variant="body1">{roundUp(absHours,2)}h</Typography>
                        </TableRowColumn>
                        <TableRowColumn style={{width:'40%'}} className={classes.td}></TableRowColumn>
                    </TableRow>
                    <TableRow>
                        <TableRowColumn style={{width:'50%'}} className={classes.td}>
                            <Typography variant="body1">Congés payés et RTT</Typography>
                        </TableRowColumn>
                        <TableRowColumn style={{width:'10%', textAlign:'right'}} className={classes.td}>
                            <Typography variant="body1">{roundUp(congHours,2)}h</Typography>
                        </TableRowColumn>
                        <TableRowColumn style={{width:'40%'}} className={classes.td}></TableRowColumn>
                    </TableRow>
                </Table>
                <Table options={{ padding: "dense", }} className={classes.table}>
                    <TableRow className={classes.trh}>
                        <TableRowColumn style={{backgroundColor:'#94BEE9', fontWeight:'bold'}} className={classes.td} colSpan={3}>
                            <Typography variant="h6" component="h2">DECOMPTE ANALYTIQUE</Typography>
                        </TableRowColumn>
                    </TableRow>
                {
                    hoursByProjects.length > 0 && hoursByProjects.map(project => (
                        <><TableRow>
                            <TableRowColumn style={{width:'50%'}} className={classes.td}>
                                <Typography variant="body1">#{project.project_code}</Typography>
                            </TableRowColumn>
                            <TableRowColumn style={{width:'10%', textAlign:'right'}} className={classes.td}>
                                <Typography variant="body1">{roundUp(project.temps,2)}h</Typography>
                            </TableRowColumn>
                            <TableRowColumn style={{width:'40%'}} className={classes.td}></TableRowColumn>
                        </TableRow></>
                    ))
                }
                </Table>
                </>
            }
            </div>
            {
                Object.keys(eventList).length > 0 && Object.keys(eventList).sort().map(week => (
                <>
                <Table options={{ padding: "dense", }} className={classes.table}>
                    <TableRow className={classes.trh}>
                        <TableRowColumn colSpan={4} style={{backgroundColor:'#94BEE9', fontWeight:'bold'}} className={classes.td}>
                            Semaine {week}
                        </TableRowColumn>
                    </TableRow>
                    {
                        Object.keys(eventList[week]).length > 0 && Object.keys(eventList[week]).map(day => (
                        <>
                        <TableRow className={classes.trh}>
                            <TableRowColumn colSpan={4} style={{fontWeight:'bold'}} className={classes.td}>
                                {day}
                            </TableRowColumn>
                        </TableRow>
                            {
                                Object.keys(eventList[week][day]).length > 0 && Object.keys(eventList[week][day]).map(e => (
                                    <>
                                    <TableRow className={classes.tr}>
                                        <TableRowColumn style={{width:'10%'}} className={classes.td}>{eventList[week][day][e].event_start_time}</TableRowColumn>
                                        <TableRowColumn style={{width:'10%'}} className={classes.td}>{eventList[week][day][e].event_end_time}</TableRowColumn>
                                        <TableRowColumn style={{width:'60%'}} className={classes.td}>{eventList[week][day][e].title}</TableRowColumn>
                                        <TableRowColumn style={{width:'20%'}} className={classes.td}>{roundUp(eventList[week][day][e].hours.num,2)}h</TableRowColumn>
                                    </TableRow>
                                    </>
                                ))
                            }
                        
                        </>
                        ))
                    }
                </Table>
                </>
            ))}
        </div>
        </FormControl>
    )}