import * as React from 'react';
import * as RA from 'react-admin';
import { useSelector } from 'react-redux';
import DefaultIcon from '@material-ui/icons/ViewList';

export const Menu = (props) => {
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(RA.getResources);
    return (
        <RA.Menu {...props}>
            <RA.DashboardMenuItem 
                primaryText="Liens"
                leftIcon={
                    <DefaultIcon />
                }
            />
            {resources.filter(resource => resource.hasList).map(resource => (
                <RA.MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            <RA.MenuItemLink to="/forecast" primaryText="Prévisions" leftIcon={<DefaultIcon />}/>
            <RA.MenuItemLink to="/events" primaryText="Evénements" leftIcon={<DefaultIcon />}/>
        </RA.Menu>
    );
}

export default Menu;