import React from 'react';
import { List, Datagrid, BooleanField, TextField, FunctionField, ReferenceField, Filter, TextInput, NullableBooleanInput, ReferenceInput, SelectInput } from 'react-admin';

const TagFilter = (props) => (
    <Filter {...props}>
        <NullableBooleanInput label="Actif" source="active" alwaysOn />
        <TextInput label="Code" source="code" alwaysOn />
        <TextInput label="Groupe d'actions" source="operation" alwaysOn />
        <ReferenceInput source="program.project_axis_id" reference="projectaxis" label="Axe stratégique" alwaysOn>
            <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput source="program.project_pole_id" reference="projectpole" label="Pôle stratégique" alwaysOn>
            <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput source="program_id" reference="programs" label="Programme" alwaysOn>
            <SelectInput source="name" />
        </ReferenceInput>
    </Filter>
);

export const TagList = (props) => (
    <List
        {...props}
        filters={<TagFilter />}
        sort={
            { field: 'code', order: 'ASC' }
        }
        bulkActionButtons={false}
    >
        <Datagrid rowClick="edit">
            <BooleanField source="active" />
            <TextField source="type" />
            <TextField source="code" />
            <TextField source="name" label="Nom" />
            <ReferenceField source="project_axis_id" reference="projectaxis" label="Axe stratégique" link={false} sortBy="program.project_axis.name">
                <FunctionField render={record => `${record.name}`} />
            </ReferenceField>
            <ReferenceField source="project_pole_id" reference="projectpole" label="Pôle stratégique" link={false} sortBy="program.project_pole.name">
                <FunctionField render={record => `${record.name}`} />
            </ReferenceField>
            <ReferenceField source="program_id" reference="programs" label="Programme" link={false} sortBy="program.name">
                <FunctionField render={record => `${record.name}`} />
            </ReferenceField>
            <ReferenceField source="manager" reference="users" label="Gestionnaire groupe d'actions" link={false}>
                <FunctionField render={record => `${record.prenom} ${record.nom}`} />
            </ReferenceField>
            <TextField source="operation" label="Groupe d'actions" />
            <TextField source="created_at" label="Crée le" />
        </Datagrid>
    </List>
);