import * as React from "react";
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import i18nProvider from './i18nProvider';
import Layout from './layout';
import { UserAccountCreate } from './useraccounts/create';
import { UserAccountEdit } from './useraccounts/edit';
import { UserAccountList } from './useraccounts/list';
import { UserCreate } from './users/create';
import { UserEdit } from './users/edit';
import { UserList } from './users/list';
import { TagCreate } from './tags/create';
import { TagEdit } from './tags/edit';
import { TagList } from './tags/list';
import { ProjectAxisCreate } from './projectaxis/create';
import { ProjectAxisEdit } from './projectaxis/edit';
import { ProjectAxisList } from './projectaxis/list';
import { ProjectPoleCreate } from './projectpole/create';
import { ProjectPoleEdit } from './projectpole/edit';
import { ProjectPoleList } from './projectpole/list';
import { ProgramCreate } from './programs/create';
import { ProgramEdit } from './programs/edit';
import { ProgramList } from './programs/list';
import dashboard from './dashboard';
import Budget from './budget';
import Events from './events';

const App = () => (
    <Admin
        title="Admin TimeManager"
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        dashboard={dashboard}
        layout={Layout}
        customRoutes={[
            <Route exact path="/forecast" component={Budget} />,
            <Route exact path="/events" component={Events} />,
        ]}
    >
        <Resource name="useraccounts" options={{ "label": "Utilisateurs" }} list={UserAccountList} create={UserAccountCreate} edit={UserAccountEdit} />
        <Resource name="users" options={{ "label": "Salariés" }} list={UserList} create={UserCreate} edit={UserEdit} />
        <Resource name="projectaxis" options={{ "label": "Axes stratégiques" }} list={ProjectAxisList} create={ProjectAxisCreate} edit={ProjectAxisEdit} />
        <Resource name="projectpole" options={{ "label": "Pôles stratégiques" }} list={ProjectPoleList} create={ProjectPoleCreate} edit={ProjectPoleEdit} />
        <Resource name="programs" options={{ "label": "Programmes" }} list={ProgramList} create={ProgramCreate} edit={ProgramEdit} />
        <Resource name="tags" options={{ "label": "Actions" }} list={TagList} create={TagCreate} edit={TagEdit} />
        <Resource name="budget" />
        <Resource name="events" />
    </Admin>
);

export default App;
