import React from 'react';
import { styled } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const ButtonContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
  }));

export default () => {
    return (
        <div>
            <Box
                sx={{
                    width: 300,
                    height: 100,
                    display: 'grid',
                }}
            >
                <ButtonContainer elevation="0">
                    <a href="http://timemanager.ledome.info" target="_blank">
                        <Button 
                            color="primary" 
                            variant="contained" 
                            size="large" 
                        >TimeManager
                        </Button>
                    </a>
                </ButtonContainer>
                <ButtonContainer elevation="0">
                    <a href="http://superset.ledome.info" target="_blank">
                        <Button 
                            color="primary" 
                            variant="contained" 
                            size="large" 
                        >Tableaux de bord - Superset
                        </Button>
                    </a> 
                </ButtonContainer>
            </Box>
        </div>
    )
}