import { List, Datagrid, BooleanField, TextField, DateField } from 'react-admin';

export const ProjectAxisList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <BooleanField source="active" label="Actif"/>
            <TextField source="code"/>
            <TextField source="name" label="Nom"/>
            <DateField source="created_at" label="Créé le" showTime/>
            <DateField source="updated_at" label="Modifé le" showTime/>
        </Datagrid>
    </List>
);