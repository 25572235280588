import { Edit, SimpleForm, BooleanInput, ReferenceInput, SelectInput, TextInput } from 'react-admin';

export const ProjectPoleEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="active" label="Actif" />
            <TextInput source="code" />
            <TextInput source="name" label="Nom" />
            <ReferenceInput source="project_axis_id" reference="projectaxis" label="Axe projet" link={false}>
                <SelectInput optionText={record => `${record.name}`} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
