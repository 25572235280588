import { List, Datagrid, TextField, DateField } from 'react-admin';

export const UserAccountList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="email" />
            <DateField source="created_at" label="Créé le" showTime/>
            <DateField source="updated_at" label="Modifé le" showTime/>
        </Datagrid>
    </List>
);