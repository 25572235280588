import React, { useState, useEffect, useCallback } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';

const Budget = () => {
    const [state, setState] = useState({
        fyList: {},
        userList: {},
        budgetList: {},
        actualFY: 0,
        actualUser: 0,
        totalHours: 0,
        newFY: true,
        initFY_Value: "",
        initFY_ErrorText: "Saisir un exercice au format YYYY-YYYY",
        disableInitButton: true
    });
    const version = useVersion();
    const dataProvider = useDataProvider();
    const fetchFyList = useCallback(async () => {
        const { data, total } = await dataProvider.getList('budget', {
            filter: {},
            sort: {
                field: 'fiscal_year',
                order: 'ASC'
            },
            pagination: {
                page: 1,
                perPage: 20
            },
            func: 'fiscalyears'
        });
        setState((state) => ({
            ...state,
            fyList: data,
            totalFyList: total
        }));
    }, [dataProvider]);
    const fetchUserList = useCallback(async () => {
        const { data, total } = await dataProvider.getList('users', {
            filter: {
                active: 1
            },
            sort: {
                field: 'prenom',
                order: 'ASC'
            },
            pagination: {
                page: 1,
                perPage: 1000
            }
        });
        setState((state) => ({
            ...state,
            userList: data,
            totalUserList: total
        }));
    }, [dataProvider]);
    const fetchBudgetList = useCallback(async (fy = 0, user = 0) => {
        if (fy && user) {
            const { data, total } = await dataProvider.getList('budget', {
                filter: {
                    fiscal_year: fy,
                    user_id: user
                },
                sort: {
                    field: ['program.project_axis.code', 'program.project_pole.code', 'program.code', 'tag.code'],
                    order: ['ASC', 'ASC', 'ASC', 'ASC']
                },
                pagination: {
                    page: 1,
                    perPage: 1000
                }
            });
            const res = await groupedBudget(data);
            setState((state) => ({
                ...state,
                budgetList: res,
                totalBudgetList: total
            }));
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchFyList();
        fetchUserList();
        fetchBudgetList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [version]);

    const {
        fyList,
        userList,
        budgetList,
        actualFY,
        actualUser,
        totalHours,
        newFY,
        initFY_Value,
        initFY_ErrorText,
        disableInitButton
    } = state;

    const setFY = (fy) => {
        setState((state) => ({
            ...state,
            actualFY: fy,
            newFY: fy === "0"
        }));
    }

    const changeFY = async (event) => {
        const changedFY = event.target.value
        setFY(changedFY)
        fetchBudgetList(changedFY, actualUser)
    };

    const initFYChange = (event) => {
        const regex = /\d{4}-\d{4}/g;
        if (event.target.value.match(regex)) {
            const fyValues = fyList.map(function (el) { return el.fiscal_year; });
            const firstFYYear = parseInt(event.target.value.split('-')[0]) || 0;
            const secondFYYear = parseInt(event.target.value.split('-')[1]) || 0;
            var errorMsg = ""
            if (fyValues.includes(event.target.value)) {
                errorMsg = "Exercice déjà existant"
            } else if ((secondFYYear - firstFYYear) !== 1) {
                errorMsg = "Exercice invalide"
            }
            setState((state) => ({
                ...state,
                initFY_Value: event.target.value,
                initFY_ErrorText: errorMsg,
                disableInitButton: fyValues.includes(event.target.value) || ((secondFYYear - firstFYYear) !== 1)
            }));
        } else {
            setState((state) => ({
                ...state,
                initFY_Value: event.target.value,
                initFY_ErrorText: "Format invalide: YYYY-YYYY",
                disableInitButton: true
            }));
        }
    }

    const initFY = async () => {
        await dataProvider.create('budget/init', {
            data: {
                fiscal_year: initFY_Value
            }
        });
        fetchFyList()
        fetchUserList()
        setState((state) => ({
            ...state,
            actualFY: initFY_Value,
            disableInitButton: true,
            newFY: false
        }));
    };

    const resyncFY = async () => {
        await dataProvider.create('budget/resync', {
            data: {
                fiscal_year: actualFY
            }
        });
        fetchFyList()
        fetchUserList()
    };

    const changeUser = (event) => {
        setState((state) => ({
            ...state,
            actualUser: event.target.value
        }));
        fetchBudgetList(actualFY, event.target.value)
    };

    const groupedBudget = async (data) => {
        var groupedBudget = {}
        if (data.length > 0) {
            const axises = [...new Set(data.map(line => line.project_axis))];
            axises.map((axis) => {
                const filteredAxisList = data.filter(line => line.project_axis === axis);
                const poles = [...new Set(filteredAxisList.map(line => line.project_pole))];

                groupedBudget[axis] = {}
                poles.map((pole) => {
                    const filteredPoleList = filteredAxisList.filter(line => line.project_pole === pole);
                    const programs = [...new Set(filteredPoleList.map(line => line.program))];

                    groupedBudget[axis][pole] = {}
                    programs.map((program) => {
                        const filteredProgramList = filteredAxisList.filter(line => line.program === program);
                        groupedBudget[axis][pole][program] = filteredProgramList
                        return true;
                    })
                    return true;
                })
                return true;
            })
        }

        await getTotalBudget(data);
        return groupedBudget;
    };

    const getTotalBudget = async (data) => {
        var totalBudget = 0;

        await data.map(line => totalBudget += line.budget);

        setState((state) => ({
            ...state,
            totalHours: totalBudget
        }));
    }

    const setBudget = async (event) => {
        const axis = event.target.id.split('|')[0]
        const pole = event.target.id.split('|')[1]
        const program = event.target.id.split('|')[2]
        const tag_id = event.target.id.split('|')[3]

        await budgetList[axis][pole][program].map(m => {
            if (m.tag_id.toString() === tag_id.toString()) {
                m.budget = event.target.value
            }
        })

        setState((state) => ({
            ...state,
            budgetList: budgetList
        }));

        if (event.target.value !== '') {

            const { data } = await dataProvider.budgetUpdate({
                data: [{
                    fiscal_year: actualFY,
                    user_id: actualUser,
                    tag_id: tag_id,
                    budget: event.target.value
                }]
            });
            getTotalBudget(data.budget);

        }
    }

    return (
        <FormControl>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <InputLabel shrink variant="standard" htmlFor="uncontrolled-native">Sélectionnez votre périmètre de prévisionnel</InputLabel>
                    <Select
                        id="fiscal_year"
                        value={actualFY}
                        defaultValue="0"
                        onChange={changeFY}
                        inputProps={{
                            name: 'fiscal_year',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <MenuItem value="0">
                            Nouvel exercice
                        </MenuItem>
                        {
                            fyList.length > 0 && fyList.map(f => (
                                <MenuItem value={f.fiscal_year}>{f.fiscal_year}</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item xs={10}>
                    {
                        newFY === false && (
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={resyncFY}
                            >Resynchroniser
                            </Button>
                        )
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        newFY === true && (
                            <TextField
                                id="init_budget"
                                label="Exercice à initialiser"
                                defaultValue=""
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                helperText={initFY_ErrorText}
                                onChange={initFYChange}
                                required
                            />
                        )
                    }
                </Grid>
                <Grid item xs={10}>
                    {
                        newFY === true && (
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                disabled={disableInitButton}
                                onClick={initFY}
                            >Initialiser
                            </Button>
                        )
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        newFY === false && (
                            <Select
                                id="user"
                                displayEmpty
                                defaultValue=""
                                onChange={changeUser}
                            >
                                <MenuItem value="" disabled>
                                    Salarié
                                </MenuItem>
                                {
                                    userList.length > 0 && userList.map(u => (
                                        <MenuItem value={u.id}>{[u.prenom, ' ', u.nom].join('')}</MenuItem>
                                    ))
                                }
                            </Select>
                        )
                    }
                </Grid>
                <Grid item xs={10}>
                    {totalHours > 0 &&
                        <Chip color="secondary" label={`Nombre total d'heures : ` + totalHours} />
                    }
                </Grid>
                {
                    Object.keys(budgetList).length > 0 && Object.keys(budgetList).map(axis => (
                        <><Grid item xs={12}>
                            <Chip label={axis} color="primary" />
                        </Grid>
                            {
                                Object.keys(budgetList[axis]).length > 0 && Object.keys(budgetList[axis]).map(pole => (
                                    <><Grid item xs={12}>
                                        <Chip label={pole} color="secondary" />
                                    </Grid>
                                        {
                                            Object.keys(budgetList[axis][pole]).length > 0 && Object.keys(budgetList[axis][pole]).map(program => (
                                                <><Grid item xs={12}>
                                                    <Chip label={program} variant="outlined" />
                                                </Grid>
                                                    {
                                                        budgetList[axis][pole][program].length > 0 && budgetList[axis][pole][program].map(b => (
                                                            <Grid item xs={1} direction="column">
                                                                <TextField
                                                                    id={[axis, pole, program, b.tag_id].join('|')}
                                                                    label={b.tag.code}
                                                                    value={b.budget}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    // onBlur={setBudget}
                                                                    onChange={setBudget}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </>
                                            ))
                                        }
                                    </>
                                ))
                            }
                        </>
                    ))
                }
            </Grid>

        </FormControl>
    )
}

export default Budget;