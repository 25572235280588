import { Create, SimpleForm, TextInput } from 'react-admin';

export const UserAccountCreate = (props) => (
    <Create {...props} >
        <SimpleForm redirect="list">
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="password" />
        </SimpleForm>
    </Create>
);
