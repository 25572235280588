import { Create, SimpleForm, BooleanInput, ReferenceInput, SelectInput, TextInput } from 'react-admin';

export const ProjectPoleCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <BooleanInput source="active" label="Actif" defaultValue={true} />
            <TextInput source="code" />
            <TextInput source="name" label="Nom" />
            <ReferenceInput source="project_axis_id" reference="projectaxis" label="Axe projet" link={false}>
                <SelectInput optionText={record => `${record.name}`} />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
