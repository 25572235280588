import { Edit, SimpleForm, BooleanInput, TextInput } from 'react-admin';

export const ProjectAxisEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="active" label="Actif"/>
            <TextInput source="code" />
            <TextInput source="name" label="Nom"/>
        </SimpleForm>
    </Edit>
);
