import { List, Datagrid, BooleanField, TextField, ReferenceField, FunctionField, DateField } from 'react-admin';

export const ProjectPoleList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <BooleanField source="active" label="Actif" />
            <TextField source="code" />
            <TextField source="name" label="Nom" />
            <ReferenceField source="project_axis_id" reference="projectaxis" label="Axe stratégique" link={false}>
                <FunctionField render={record => `${record.name}`} />
            </ReferenceField>
            <DateField source="created_at" label="Créé le" showTime />
            <DateField source="updated_at" label="Modifé le" showTime />
        </Datagrid>
    </List>
);