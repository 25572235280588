require('dotenv').config();

export default {
    login: ({ username, password }) => {
        const request = new Request(
            `${process.env.REACT_APP_API_URL || ''}/api/login`,
            {
                method: 'POST',
                body: JSON.stringify({ email: username, password }),
                headers: new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': 'true' }),
                credentials:
                    `${process.env.REACT_APP_HTTP_CREDENTIALS || 'same-origin'}`
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                var r = response.json()
                return r;
            })
            .then(({ access_token }) => {
                localStorage.setItem('token', access_token);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: (error) => {
        return error.status === 403 ? Promise.reject() : Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: params => Promise.resolve(),
};
