import { List, Datagrid, BooleanField, TextField, DateField } from 'react-admin';

export const UserList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <BooleanField source="active" />
            <TextField source="nom" />
            <TextField source="prenom" />
            <TextField source="work_days.days" label="Jours travaillés" />
            <TextField source="days_off.days" label="Jours off" />
            <TextField source="work_quota" label="Quotité temps" />
            <TextField source="comment" />
            <DateField source="created_at" label="Créé le" showTime/>
            <DateField source="updated_at" label="Modifé le" showTime/>
        </Datagrid>
    </List>
);