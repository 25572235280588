import { Create, SimpleForm, BooleanInput, TextInput, ReferenceInput, SelectInput } from 'react-admin';

export const ProgramCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <BooleanInput source="active" label="Actif" defaultValue={true} />
            <TextInput source="code" />
            <TextInput source="name" label="Nom" />
            <ReferenceInput source="project_pole_id" reference="projectpole" label="Pôle projet" link={false}>
                <SelectInput optionText={record => `${record.name}`} />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
