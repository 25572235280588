import { Edit, SimpleForm, BooleanInput, TextInput, ReferenceInput, SelectInput } from 'react-admin';

export const ProgramEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="active" label="Actif" />
            <TextInput source="code" />
            <TextInput source="name" label="Nom" />
            <ReferenceInput source="project_pole_id" reference="projectpole" label="Pôle projet" link={false}>
                <SelectInput optionText={record => `${record.name}`} />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
