import Polyglot from "node-polyglot";
import phrases from "ra-language-french";

const polyglot = new Polyglot({
    phrases: {
        "Yes": "Oui",
        ...phrases,
    }
})

export default  {
    changeLocale: Promise.resolve,
    getLocale: () => {
        return "fr";
    },
    translate: (key, options) => {
        return polyglot.t(key, options);
    },
};
